.xdsoft_datetimepicker{
	box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.506);
	background: #FFFFFF;
	border-bottom: 1px solid #BBBBBB;
	border-left: 1px solid #CCCCCC;
	border-right: 1px solid #CCCCCC;
	border-top: 1px solid #CCCCCC;
	color: #333333;
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	padding: 8px;
	padding-left: 0px;
	padding-top: 2px;
	position: absolute;
	z-index: 9999;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display:none;
}

.xdsoft_datetimepicker iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 210px;
    background: transparent;
    border:none;
}
/*For IE8 or lower*/
.xdsoft_datetimepicker button {
    border:none !important;
}

.xdsoft_noselect{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.xdsoft_noselect::selection { background: transparent; }
.xdsoft_noselect::-moz-selection { background: transparent; }
.xdsoft_datetimepicker.xdsoft_inline{
	display: inline-block;
	position: static;
	box-shadow: none;
}
.xdsoft_datetimepicker *{
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding:0px;
	margin:0px;
}
.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker  .xdsoft_timepicker{
	display:none;
}
.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker  .xdsoft_timepicker.active{
	display:block;
}
.xdsoft_datetimepicker .xdsoft_datepicker{
	width: 224px;
	float:left;
	margin-left:8px;
}
.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker{
	width: 256px;
}
.xdsoft_datetimepicker  .xdsoft_timepicker{
	width: 58px;
	float:left;
	text-align:center;
	margin-left:8px;
	margin-top:0px;
}
.xdsoft_datetimepicker  .xdsoft_datepicker.active+.xdsoft_timepicker{
	margin-top:8px;
	margin-bottom:3px
}
.xdsoft_datetimepicker  .xdsoft_mounthpicker{
	position: relative;
	text-align: center;
}
.xdsoft_datetimepicker .xdsoft_label i{
	opacity:0.5;
	background-position:-92px -19px;
	display: inline-block;
	width: 9px;
	height: 20px;
	vertical-align: middle;
}

.xdsoft_datetimepicker  .xdsoft_prev{
    float: left;
	background-position:-20px 0px;
}
.xdsoft_datetimepicker  .xdsoft_today_button{
    float: left;
	background-position:-70px 0px;
	margin-left:5px;
}

.xdsoft_datetimepicker  .xdsoft_next{
    float: right;
	background-position:0px 0px;
}
.xdsoft_datetimepicker  .xdsoft_next:active,.xdsoft_datetimepicker  .xdsoft_prev:active{
}
.xdsoft_datetimepicker  .xdsoft_next,
.xdsoft_datetimepicker  .xdsoft_prev ,
.xdsoft_datetimepicker  .xdsoft_today_button{
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0px none currentColor;
	cursor: pointer;
	display: block;
	height: 30px;
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	outline: medium none currentColor;
	overflow: hidden;
	padding: 0px;
	position: relative;
	text-indent: 100%;
	white-space: nowrap;
	width: 20px;
}
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_prev,
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_next{
	float:none;
	background-position:-40px -15px;
	height: 15px;
	width: 30px;
	display: block;
	margin-left:14px;
	margin-top:7px;
}
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_prev{
	background-position:-40px 0px;
	margin-bottom:7px;
	margin-top:0px;
}
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_time_box{
	height:151px;
	overflow:hidden;
	border-bottom:1px solid #DDDDDD;
}
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_time_box >div >div{
	background: #F5F5F5;
	border-top:1px solid #DDDDDD;
	color: #666666;
	font-size: 12px;
	text-align: center;
	border-collapse:collapse;
	cursor:pointer;
	border-bottom-width:0px;
	height:25px;
	line-height:25px;
}

.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_time_box >div > div:first-child{
 border-top-width:0px;
}
.xdsoft_datetimepicker  .xdsoft_today_button:hover,
.xdsoft_datetimepicker  .xdsoft_next:hover,
.xdsoft_datetimepicker  .xdsoft_prev:hover {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.xdsoft_datetimepicker  .xdsoft_label{
	display: inline;
    position: relative;
    z-index: 9999;
    margin: 0;
    padding: 5px 3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    background-color: #fff;
	float:left;
	width:182px;
	text-align:center;
	cursor:pointer;
}
.xdsoft_datetimepicker  .xdsoft_label:hover{
	text-decoration:underline;
}
.xdsoft_datetimepicker  .xdsoft_label:hover i{
	opacity:1.0;
}
.xdsoft_datetimepicker  .xdsoft_label > .xdsoft_select{
	border:1px solid #ccc;
	position:absolute;
	right:0px;
	top:30px;
	z-index:101;
	display:none;
	background:#fff;
	max-height:160px;
	overflow-y:hidden;
}
.xdsoft_datetimepicker  .xdsoft_label > .xdsoft_select.xdsoft_monthselect{right:-7px;}
.xdsoft_datetimepicker  .xdsoft_label > .xdsoft_select.xdsoft_yearselect{right:2px;}
.xdsoft_datetimepicker  .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover{
	color: #fff;
    background: #ff8000;
}
.xdsoft_datetimepicker  .xdsoft_label > .xdsoft_select > div > .xdsoft_option{
	padding:2px 10px 2px 5px; 
}
.xdsoft_datetimepicker  .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current{
	background: #33AAFF;
	box-shadow: #178FE5 0px 1px 3px 0px inset;
	color:#fff;
	font-weight: 700;
}
.xdsoft_datetimepicker  .xdsoft_month{
	width:100px;
	text-align:right;
}
.xdsoft_datetimepicker  .xdsoft_calendar{
	clear:both;
}
.xdsoft_datetimepicker  .xdsoft_year{
	width: 48px;
	margin-left: 5px;
}
.xdsoft_datetimepicker  .xdsoft_calendar table{
	border-collapse:collapse;
	width:100%;
	
}
.xdsoft_datetimepicker  .xdsoft_calendar td > div{
	padding-right:5px;
}
.xdsoft_datetimepicker  .xdsoft_calendar th{
	height: 25px;
}
.xdsoft_datetimepicker  .xdsoft_calendar td,.xdsoft_datetimepicker  .xdsoft_calendar th{
	width:14.2857142%;
	background: #F5F5F5;
	border:1px solid #DDDDDD;
	color: #666666;
	font-size: 12px;
	text-align: right;
	vertical-align: middle;
	padding:0px;
	border-collapse:collapse;
	cursor:pointer;
	height: 25px;
}
.xdsoft_datetimepicker.xdsoft_showweeks  .xdsoft_calendar td,.xdsoft_datetimepicker.xdsoft_showweeks  .xdsoft_calendar th{
	width:12.5%;
}
.xdsoft_datetimepicker  .xdsoft_calendar th{
	background: #F1F1F1;
}
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_today{
	color:#33AAFF;
}
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current{
	background: #33AAFF;
	box-shadow: #178FE5 0px 1px 3px 0px inset;
	color:#fff;
	font-weight: 700;
}
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_other_month,
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_disabled,
.xdsoft_datetimepicker  .xdsoft_time_box >div >div.xdsoft_disabled{
	opacity:0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled{
	opacity:0.2;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}
.xdsoft_datetimepicker  .xdsoft_calendar td:hover,
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_time_box >div >div:hover{
	color: #fff !important;
    background: #ff8000 !important;
    box-shadow: none !important;
}
.xdsoft_datetimepicker  .xdsoft_calendar td.xdsoft_disabled:hover,
.xdsoft_datetimepicker  .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_disabled:hover{
	color: inherit	!important;
    background: inherit !important;
    box-shadow: inherit !important;
}
.xdsoft_datetimepicker  .xdsoft_calendar th{
	font-weight: 700;
	text-align: center;
	color: #999;
	cursor:default;
}
.xdsoft_datetimepicker  .xdsoft_copyright{ color:#ccc !important; font-size:10px;clear:both;float:none;margin-left:8px;}
.xdsoft_datetimepicker  .xdsoft_copyright a{ color:#eee !important;}
.xdsoft_datetimepicker  .xdsoft_copyright a:hover{ color:#aaa !important;}


.xdsoft_time_box{
	position:relative;
	border:1px solid #ccc;
}
.xdsoft_scrollbar >.xdsoft_scroller{
	background:#ccc !important;
	height:20px;
	border-radius:3px;
}
.xdsoft_scrollbar{
	position:absolute;
	width:7px;
	right:0px;
	top:0px;
	bottom:0px;
	cursor:pointer;
}
.xdsoft_scroller_box{
position:relative;
}

